<template>
  <b-input
    :value="values[field.id]"
    size="is-small"
    type="password"
    :disabled="field.properties.basic.isDisabled"
    :password-reveal="field.properties.basic.isPasswordVisible"
    placeholder="*******"
    @input="handleInput"
    @blur="handleBlur()"
    v-on="fieldEvents"
  />
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const handleInput = value => {
    emit('update', {
      value
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  const handleBlur = () => {
    emit('blur');
  };
  return {
    handleInput,
    handleBlur
  };
};
export default __sfc_main;
</script>
